import React, { useState } from 'react'
import styled from 'styled-components';
import { devices } from '../styled-components/Devices';

export default function ContactInfo({icon, content, link}) {
    const [hover, setHover] = useState(false)
    return (
        <a href={link} target={link && "_blank"} 
            onMouseEnter={() => setHover(true)} 
            onMouseLeave={() => setHover(false)}> 
        <Container >
            <IconContainer hover={hover}>
            {icon}
            </IconContainer>
            {content}
        </Container>
        </a>
    )
}

const Container = styled.div`
    font-size: 1.5rem;
    @media ${devices.mobile} {
        font-size: 1rem;
    }


    color: #E3E3E3;
    cursor: pointer;
    &:hover {
        font-weight: bold;
    }
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    height: 4.5rem;
    @media ${devices.mobile} {
        height: 3rem;
    }
`;

const IconContainer = styled.div`
    font-size: 2rem;
    @media ${devices.mobile} {
        font-size: 1.2rem;
        padding: 10px 10px;
        border-width: 2px;
    }
    color: #E3E3E3;
    border: solid 3px #E3E3E3;
    border-width: ${props => props.hover && "4px"};
    border-radius: 50%;
    width: fit-content;
    padding: 15px 15px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    
`;