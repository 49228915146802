import React, { useState } from 'react'
import styled from 'styled-components';

export default function TimeLineItemContent({data}) {
    

    return (
        <TimeLineItemContentDiv >
            <UpperDiv>
                <Image src={data.image} />
                
                <HeaderDiv>
                    <Meta>
                            {data.timespan}
                    </Meta>
                    <Text>
                    {data.header} 
                    </Text>
                    
                </HeaderDiv>
            </UpperDiv>
            
        </TimeLineItemContentDiv>
    )
}

const TimeLineItemContentDiv = styled.div`

`;

const UpperDiv = styled.div`
    display: flex;    
`;

const Image = styled.img`
    width: 40%;
    height: 100%;
    object-fit: cover;
    margin-right: 1rem;
`;

const HeaderDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
`;


const Meta = styled.div`
    color: #45B69C;
    position: relative;
    align-self: flex-start;
    margin-bottom: 1rem;
    font-weight: bold;
`;

const Text = styled.div`

`;

