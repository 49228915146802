import Royal from "../media/royal.png"
import LKF from "../media/lkf.png"
import ÖKK from "../media/ökk.png"
import Allingsås from "../media/allingsås.png"
import Phantasialand from "../media/phantasialand.png"
import PortAventura from "../media/portaventura.png"

export const IntroText = 
"After many years of competing on international level "+
"in figure skating, I decided to start skating professionally "+
"in shows. I'm an energetic skater, loving to act and do acrobatic "+
"tricks on ice."

export const experiences = [
    {
        title: "Linköpings KF",
        years: "2014-18",
        location: "Sweden",
        image: LKF,
    },
    {
        title: "Örnsköldsviks KK",
        years: "2017",
        location: "Sweden",
        image: ÖKK,
    },
    {
        title: "Allingsås KK",
        years: "2017-18",
        location: "Sweden",
        image: Allingsås,
    },
    {
        title: "Phantasialand",
        years: "2021",
        location: "Germany",
        image: Phantasialand,
    },
    {
        title: "Royal Caribbean",
        years: "2022",
        location: "Caribbeans",
        image: Royal,
    },
    {
        title: "Port Aventura",
        years: "2022",
        location: "Spain",
        image: PortAventura,
    },
]