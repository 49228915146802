import React, { useRef } from 'react'
import {gsap} from 'gsap'
import ArrowsDown from "../blocks/ArrowsDown"
import styled from 'styled-components';
import { SectionDiv, EndOfViewDiv, FadeUp } from '../styled-components/Layout';
import ScrollDown from '../blocks/ScrollDown';
import ContactInfo from '../blocks/ContactInfo';
import { FaPhoneAlt, FaLinkedin } from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';
import { CallsToAction } from '../styled-components/Typography';
import { devices, isMobileQuery } from '../styled-components/Devices';
import { useMediaQuery } from 'react-responsive';
//import "../css/XXXXXX.css"
import AnimatedSection from '../styled-components/AnimatedSection';

export default function Welcome() {
  const isMobile = useMediaQuery(isMobileQuery)
  const ref = useRef(null)

  

    return (
      

        <SectionDiv id="welcome" color="#121212" ref={ref}>
          <AnimatedSection>
            <IntroDiv id="box">
            
              <BigTextIntroDiv>
              
                
                  I'm Daniel Engelsons. An ice show skater from Linköping, Sweden programming robots, 
                  websites and apps to make our world more automated and life more exciting. 
                
              </BigTextIntroDiv>
              
              <SmallTextIntroDiv>
                <CallsToAction size={isMobile ? "1.5rem" : "3rem"}>            
                  Let's do it together!            
                </CallsToAction>
              </SmallTextIntroDiv>
              
            </IntroDiv>
            </AnimatedSection>
            <AnimatedSection>
            <ContactDiv>
            
            <ContactInfo content={"daniel@engelsons.org"} icon = {<GrMail/>} link= "mailto: daniel@engelsons.org" />
              <ContactInfo content={"+46 709 54 63 97"} icon = {<FaPhoneAlt/>}/>
              
              <ContactInfo content="Chat on LinkedIn" icon = {<FaLinkedin/>} link="https://www.linkedin.com/in/daniel-engelson-a5114b154/"  />
              <SmallTextIntroDiv>
              <CallsToAction size={"3rem"}>  
                Let's talk!
                </CallsToAction>
              </SmallTextIntroDiv>
              
            </ContactDiv>
            </AnimatedSection>
        </SectionDiv> 
      

    )
}
const IntroDiv = styled.div`
    margin-top: 15%;
    width: 80%;
    margin-left: 20%;
    box-sizing: border-box ;
    @media ${devices.mobile} {
      width: 80%;
      margin-left: 10%;
      margin-top: 200px;
    }
`;

const ContactDiv = styled.div`
    margin-top: -45px;
    width: 40%;
    margin-left: 60%;
    box-sizing: border-box ;
    @media ${devices.mobile} {
      margin-top: 50px;
      width: 60%;
      margin-left: 20%;
    }
`;

const BigTextIntroDiv = styled.div`
  @media ${devices.mobile} {
      font-size: 1.4rem;
      width: 100%;
      text-align: center ;
    }

    font-size: 3rem;
    font-family: "Exo 2", "sans-serif";
    /*font-style: italic;*/
    color: #E3E3E3;
    width: 40%;
`;

const SmallTextIntroDiv = styled.div`
    margin-top: 50px;
    font-size: 25px;
    color: #E3E3E3;
    width: 40%;
    @media ${devices.mobile} {
      width: 100%;
      text-align: center ;
    }
`;

