import React from 'react'
import styled from 'styled-components'
import ArrowsDown from '../blocks/ArrowsDown'
import { devices } from '../styled-components/Devices'

export default function ProjectSimpleView( {project, focusSlide, thisSlide, totalSlides, setChosenProject }) {
    const focusSlideIndex = focusSlide % totalSlides
    
    const getPosition = () => {
        if (focusSlideIndex == 0) {
            return -thisSlide
        } else if (thisSlide == 0 && focusSlideIndex == totalSlides - 1 ) {
            return -1
        } else {
            return (focusSlideIndex - thisSlide) 
        }
    }
    const position =  getPosition();
    return (
            
        <ProjectSimpleViewDiv position={position} onClick={() => {setChosenProject && setChosenProject(project)}}>
            
            <HeaderDiv >
                <BigText className="HeaderText">
                    {project.name}
                </BigText>
                {focusSlide % totalSlides == thisSlide && <SmallText className="Content">
                    {project.shortDescription}
                </SmallText>}
            </HeaderDiv>
            <Image src={project.image} alt={project.name}/>
        </ProjectSimpleViewDiv>
        
    )
}

const ProjectSimpleViewDiv = styled.div`

    @keyframes fadeInFull {
        0% {opacity: 33%;}
        100% {opacity: 100%;}
    }
    @keyframes fadeInHalf {
        0% {opacity: 15%;}
        100% {opacity: 33%;}
    }
    @keyframes fadeOut {
        0% {opacity: 100%;}
        100% {opacity: 15%;}
    }
    
    height: calc(300px + 8rem + 80px);

    margin-left: 30%;
    margin-right: -25%;

    @media ${devices.mobile} {
        margin-left: 0px;
        margin-right: 0px;
        height: calc(300px + 40px);
    }

    opacity: ${props => {
        if (props.position == 0) return "100%"
        if (props.position == -1) return "33%"
        return "15%"
    }};

    @media ${devices.mobile} {
        opacity: 1;
        animation-name: none
    }

    animation-name: ${props => {
        if (props.position == 0) return "fadeInFull"
        if (props.position == -1) return "fadeInHalf"
        return "fadeOut"
    }};

    animation-duration: 0.5s;

    cursor: pointer;
    overflow: hidden;

    @media ${devices.desktop} {
        transition: transform 0.5s;
        &:hover {
            opacity: 100%;
            transform: scale(1.1);
        }
    }
`

const Image = styled.img`
    height: 300px;
    width: 100%;
    object-fit: cover;

`

const BigText = styled.div`
    font-size: 1.5rem;
    @media ${devices.mobile} {
        background: #121212;
        padding: 3px 7px;
        width: fit-content;
        color: #FFF;
    }
`

const SmallText = styled.div`
    font-size: 1.2rem;
    margin-top: 5px;

    @keyframes moveUp {
        0% {margin-bottom: -2rem; opacity: 0}
        100% {margin-bottom: 0rem; opacity: 1}
    }
    animation-name: moveUp;
    animation-duration: 0.5s;

    @media ${devices.mobile} {
        background: #121212;
        padding: 3px 7px;
        width: fit-content;
        color: #FFF;
    }
`

const HeaderDiv = styled.div`
    height: 8rem;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-end;
    margin-bottom: 15px;
    
    @media ${devices.mobile} {
        top: 150px;
        position: absolute;
        padding: 0px 10px;
        font-weight: bolder;
        z-index: 500;
    }
    
`
