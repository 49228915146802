import CDIO from "../media/cdio.jpg"
import AIRobotics from "../media/AIRoboticsRobot.png"
import Kandidat from "../media/RacerCarRobot.png"

import AIRoboticsPaper from "../media/TDDE05_report.pdf"

export const IntroText = 
"During 2016-2021 I studied at Linköping University in Sweden. "+
"I have a Master degree with a major in Applied Physics and "+
"Electrical Engineering (Swedish: Teknsik Fysik och elektroteknik) "+ 
"with a specialization in Mechatronics. During my Bachelor years I got a good "+
"base in math, physics, electronics and programming. My Master was focused "+
"on automatic control and modeling, but in addition I chose "+
"courses in the field of programming, robotics and AI."

export const ResearchText = 
"My research during my Master Thesis resulted in a conference paper at ICRA 2022, "+
"which is one of the world's top premier conferences in the field of robotics. I "+
"developed a new Coverage Path Planning algorithm with state-of-the-art performance in "+
"large-scale multi-floor environments. The application of this research is "+
"autonomous road sweeping of complex urban environments such as bridges, parking garages and "+
"city streets. "

export const projects = [
    {
        type: "Education",
        name: "Search & Resque Simulation Platform",
        shortDescription: "Autonomous searching for people in distress using two tracked vehicles and a drone. ",
        longDescription:  "The system simulates a mission in Gazebo where two tracked " + 
        " vehicles and a drone searches for persons in distress autonomously communicating " + 
        "over a ROS network. The user can interact with the system via a user-friendly GUI. "  +
        " By using the GUI, the user of the system can test different options for the robots and " + 
        " specify the environment of the mission area. \n\n This project was made in the CDIO project course TSRT10",
        image: CDIO,
        youtubeURL: "https://youtu.be/iAegGlH-vzA",
        links: [
            {
                label: "Project Website",
                link: "http://www.isy.liu.se/edu/projekt/tsrt10/2020/sbd/"
            }
        ]
    },
    {
        type: "Education",
        name: "Autonomous Race Car",
        shortDescription: "A small car, able to drive in an unknown environment using LIDAR and IR sensors",
        longDescription: "We developed a race car that could drive autonomously in an unknown racing track " +
        "with walls and obstacles. \n \nThe robot navigated using a LIDAR sensor and Time of Flight Distance " +
        " sensors. The data were processed using a Rasberry Pi and a pair of Micro Processors. \n \n I was the manager"+
        " of our project and worked mostly with the sensors and the algorithm for the autonomous driving.",
        image: Kandidat, 
        youtubeURL: "https://youtube.com/shorts/cR1DRLn9BRU?feature=share"

        //"course": "Electronics Engineering - Bachelor Project, TSEA56",
        //"skills": "C, Python, Electronics, Automatic Control",
        //"language": "Swedish"
    },
    {
        type: "Education",
        name: "Evaluation of an Exploring Algorithm",
        shortDescription: "Naive Active Frontier Detection implemented and simulated",
        longDescription: "In a couple of years from now robots will be a big a part of our lives. "+ 
        " They will have to navigate in different situations and if they get placed in new "+
        "environments they need to be able to explore them as fast as possible. \n \n In this "+
        " report I compared the Naive Active Frontier Detection algorithm with other frontier "+
        " detection algorithms (WFD and FFD). I compared their computational time of detecting "+
        " frontiers and their speed of exploring new environments. The implementations of the "+
        " algorithms were made in the Robotic Operating System (ROS) and simulated with a Husky "+
        " robot in an office environment.",
        //"course": "AI Robotics, TDDE05",
        //"skills": "ROS, C++, Python",
    //    "language": "English"

        image: AIRobotics,
        links: [
            {
                label: "Read Paper",
                link: AIRoboticsPaper
            }
        ]
    },
]
