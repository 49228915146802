import React, { useState } from 'react'
//import EducationDescription from "./ProjectDetailedViews/EducationDescription"
import ReactPlayer from "react-player"
import styled from 'styled-components'
import { devices } from '../styled-components/Devices'
import Popup from './Popup'
export default function ProjectDetailedView({project}) {
    const [onVideoLoad, setVideoLoading] = useState(true)
    console.log(project)

    let longDescription = project.longDescription.split('\n').map((paragraph, i) => {
        return <p key={i}>{paragraph}</p>;
    });

    const media = () => {
        let videoUrl;
        if(project.video) {
            //Not working atm
            videoUrl = 
                {src: project.video, type: 'video/mp4'}
            
        } else if (project.youtubeURL) {
            videoUrl = project.youtubeURL
        } 
        if (videoUrl) {
            return <Video
                url={videoUrl}
                onReady={() => {console.log("onREady"); setVideoLoading(false)}}
                muted={true}
                playing={true}
                controls={true}
                loop={true}
                width="100%"
                height="40vh"
            
            />
        } else {
            return <Image src={project.image} alt={project.name}/>
        }
    }

    return (
        <div>
                <MediaArea >
                    {onVideoLoad && <Image src={project.image} alt={project.name}/>}
                    {media()}
                </MediaArea>
                <TextArea>
                    <TitleArea>
                        {project.name}
                    </TitleArea>
                    <DescriptionArea>
                        {longDescription}
                    </DescriptionArea>
                    <LinksArea>
                    {project.links && project.links.map( (link, index) => {
                        return (
                        <ReadMoreButton key={index} href={link.link} target={"_blank"}>
                            {link.label}
                        </ReadMoreButton>)
                    } )}
                
                    </LinksArea>
                </TextArea>
        </div>
    )
}

const MediaArea = styled.div`
    width: 100%;
    height: 40vh;
`;

const TextArea = styled.div`
    @media ${devices.mobile} {
        padding: 30px;
    }
    padding: 80px;
    background-color: #1F1F1F;
    color: #FFF;
`;


const Image = styled.img`
    position: absolute ;
    width: 100%;
    height: 40vh;
    z-index: 500;
    object-fit: cover;
`

const Video = styled(ReactPlayer)`
    position: absolute ;
    width: 100%;
    height: 40vh;
    z-index: 400;
`

const TitleArea = styled.div`
    font-size: 2rem;
`;

const DescriptionArea = styled.div`
    margin: 20px 0px 28px 0px;
`;

const LinksArea = styled.div`
    display: flex;
`;

const ReadMoreButton = styled.a`
    padding: 15px 30px;
    border: 2px solid #FFF;
    text-decoration: none;
    color: #FFF;
    background-color: #121212;
    width: fit-content;
    border-radius: 5px;
    margin-right: 15px;

    &:hover{
        background-color: #FFF;
        color: #45B69C;
        border: 2px solid #45B69C;
    }
`;
