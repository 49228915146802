import React, { useEffect } from 'react'
import styled from 'styled-components';
import {ImCross} from 'react-icons/im'
import { devices } from '../styled-components/Devices';

export default function Popup({children, close}) {

    //document.getElementsByTagName("html").style.overflowY = 'hidden'
    
    useEffect( () => {
        document.body.style.overflowY = 'hidden'
    })
    

    return (
        <div>
            <PopUpDiv id="popup">
                <Exit onClick={() => {
                    document.body.style.overflowY = 'visible'
                    close()}}>
                    <ImCross/>
                </Exit>
                {children}
            </PopUpDiv>
            <PopUpBackgroundDiv onClick={() => {
                    document.body.style.overflowY = 'visible'
                    close()}}/>
        </div>
    )
}

const Exit = styled.div`
    position: fixed;
    left: calc(50vw + 450px - 4rem);
    top: calc(10vh + 0.5rem);
    color: #FFF;
    z-index: 900;
    border: solid 5px #333;
    border-radius: 100%;
    padding: 1rem;
    width: 1rem;
    height: 1rem;
    display: flex;
    justify-content: center;
    font-weight: bolder;
    background: #333;
    cursor: pointer;
    @keyframes fadeIn {
        0% {
            opacity: 0%;
        }
        50% {
            opacity: 0%;
        }
        100% {
            opacity: 100%;
        }
    }

    animation-name: fadeIn;
    animation-duration: 2s;
`;

const PopUpDiv = styled.div`
    top: 0vh;
    z-index: 200;
    overflow-y: scroll !important;
    position: fixed;

    min-height: 80vh;
    max-height: 90vh;
    width: 900px;
    
    
    padding-top: 10vh;
    left: calc(50vw - 450px);
    
    
    
    ::-webkit-scrollbar {
        display: none;
    }
    
    @keyframes fadeInAndMove {
        0% {
            top: 5vh;
            opacity: 0%;
            
        }
        100% {opacity: 100%;
            top: 0vh;
        }
    }
    animation-name: fadeInAndMove;
    animation-duration: 1s;
    
`;

const PopUpBackgroundDiv = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    z-index: 100;
    opacity: 0.7;
    cursor: pointer;
    overflow-y: scroll !important;
`;