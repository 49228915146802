import React from 'react'
import styled from 'styled-components'
import Video from '../blocks/Video'
import { SectionContentDiv, SectionDiv, SectionHeaderDiv } from '../styled-components/Layout'
import { SectionHeader, SectionSubHeader, SectionText } from '../styled-components/Typography'
import MasterThesis from './MasterThesis'
import SchoolProject from './SchoolProject'
import {IntroText, projects} from "../../data/AcademicData"
import background from "../../media/academic_bg-1.png" 
import SchoolProjects from './SchoolProjects'
import { devices } from '../styled-components/Devices'
import AnimatedSection from '../styled-components/AnimatedSection'

export default function Academic() {
    

    return (
        <AcademicDiv id="academic" color="#121212">
        
            <SectionContentDiv >
            <AnimatedSection>
                <SectionHeader color={"#121212"} className="HeaderText">
                    Academic
                </SectionHeader>
                <SectionText color={"#121212"} >
                    {IntroText}
                </SectionText>
                </AnimatedSection>
                <AnimatedSection>
          
                <MasterThesis />
                </AnimatedSection>
                <AnimatedSection>
                <SectionSubHeaderDiv color={"#121212"} id="schoolprojects">
                    School Projects
                </SectionSubHeaderDiv>
                <SchoolProjectsDiv>
                    <SchoolProjects projects={projects} />
   
                </SchoolProjectsDiv>
                </AnimatedSection>
                
            </SectionContentDiv>
            
            
        </AcademicDiv>
    )
}

const AcademicDiv = styled(SectionDiv)`
    padding: 150px 0px;
    background-image: url(${background});
    background-position: right;  
    background-color: #FFF;
    background-attachment: fixed;  
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    width: 100%;
    display: flex;
    @media ${devices.mobile} {
        background-image: none;
        margin-bottom: -200px;
    };
`;

const SchoolProjectsDiv = styled.div`
    width: 100%;
    
`;

const SectionSubHeaderDiv = styled(SectionSubHeader)`
    margin-top: 45px;
    margin-bottom: 45px;
    @media ${devices.mobile} {
        margin-bottom: 30px;
        margin-top: 100px;
    };
`;