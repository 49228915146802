import logo from './logo.svg';
//import './css/main.scss';
import Header from './components/1_header/Header';
import styled from 'styled-components';
import Contact from './components/7_contact/Contact';
import Projects from './components/3_projects/Projects';
import Experience from './components/5_experience/Experience';
import Skating from './components/6_skating/Skating';
import Academic from './components/4_academic/Academic';
import Welcome from './components/2_welcome/Welcome';
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";


import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  ScrollRestoration
 } from "react-router-dom";
import MobilePopup from './components/3_projects/MobilePopup';
import { useEffect, useState } from 'react';
import { motion, useScroll, useSpring } from 'framer-motion';



export default function App() {
  gsap.registerPlugin(ScrollTrigger);

  const location = useLocation();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  const scrollTo = location.state && location.state.scrollTo

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
    if (scrollTo) {
      var Scroll = require('react-scroll');
      var scroller = Scroll.scroller;
      scroller.scrollTo(scrollTo, {
          duration: 1,
          smooth: 'easeInOutQuint',
      });
    }
  }, [location, displayLocation]);

  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });

  console.log(scrollYProgress)

  return (    
    <TransitionManager
      transitionStage={transitionStage}
      onAnimationEnd={() => {
        if (transitionStage === "fadeOut") {
          setTransistionStage("fadeIn");
          setDisplayLocation(location);
        }
      }}
      >
        <Routes location={displayLocation} preserveScrollPosition={true}>
          
          <Route path="/" element={
            <div id="page" style={{overflowX: "hidden"}}>
                <Header/> 
                
                <Welcome />
                
                <Projects />
                <Academic />
                <Experience />
                <Skating />
                <Contact />
                <motion.div style={{ scaleX: scaleX, 
                    ...ProgressBar}} />
  
            </div>
          } />
          <Route path="/project" element={
            <MobilePopup/>
          } />
        </Routes>
      </TransitionManager>
  );
}

const ProgressBar = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  height: "10px",
  background: "#45B69C",
  transformOrigin: "0%",
  zIndex: 999
}

const TransitionManager = styled.div`
  @keyframes fadeInTransition {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOutTransition {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
  animation: ${props => props.transitionStage == "fadeIn" ? " 0.5s fadeInTransition forwards" : "0.5s fadeOutTransition forwards"};

`;