import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Popup from '../3_projects/Popup';
import ProjectDetailedView from '../3_projects/ProjectDetailedView';
import ProjectsCarousel from '../3_projects/ProjectsCarousel';
import { isMobileQuery } from '../styled-components/Devices';
import { CallsToAction } from '../styled-components/Typography';
import SchoolProject from './SchoolProject'

export default function SchoolProjects({projects}) {
    const isMobile = useMediaQuery(isMobileQuery)

    const [focusProject, setFocusProject] = useState(projects[0])
    const [chosenProject, setChosenProject] = useState(false)
    const [imageHover, setImageHover] = useState(false)
    return (
        <SchoolProjectsDiv>
            {isMobile && <ProjectsCarousel content={projects} scrollTo={"schoolprojects"} textColor={"#121212"}/>}
            {!isMobile && <DesktopDiv> 
            <ImageDiv onMouseEnter={() => setImageHover(true)} 
                        onMouseLeave={() => setImageHover(false)}
                        onClick={() => setChosenProject(focusProject)}>
                {imageHover && <ReadMoreDiv>
                    <ReadMore>Read More</ReadMore>
                </ReadMoreDiv>}

                <Image src={focusProject.image} imageHover={imageHover}/>
            </ImageDiv>
            <ProjectListDiv>
                {projects.map( (project, index) => <SchoolProject 
                    key={index}
                    project={project} 
                    total={projects.length} 
                    setFocusProject={setFocusProject}
                    setChosenProject={setChosenProject}
                    /> )}
            </ProjectListDiv>  
            </DesktopDiv>}
            {!isMobile && chosenProject && <Popup close={() => setChosenProject(false)}> 
                <ProjectDetailedView 
                    project={chosenProject}
                    close={() => setChosenProject(false)} />
            </Popup>}   
        </SchoolProjectsDiv>
    )
}

const SchoolProjectsDiv = styled.div`
    display: flex;
`;

const DesktopDiv = styled.div`
    display: flex;
`;

const Image = styled.img`
    width: 100%;
    object-fit: cover;
    height: 100%;
    z-index: 250;

    @keyframes fadeOutOnHover {
        0% {opacity: 100%;}
        100% {opacity: 50%;}
    }
    
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-name: ${props => props.imageHover && "fadeOutOnHover"};
    opacity: ${props => props.imageHover ? "50%" : "100%"};
`;

const ImageDiv = styled.div`
    width: 50%;
    cursor: pointer;
    height: 400px;
    background-color: #000;
    
`;

const ReadMoreDiv = styled.div`
    
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 350;
    position: absolute;
    height: 400px;
    width: 29.7vw;
    opacity: 100%;
    @keyframes fadeInReadMore {
        0% {opacity: 0%;}
        100% {opacity: 100%;}
    }
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-name: fadeInReadMore;
`;

const ReadMore = styled(CallsToAction)`
    font-size: 3rem
`;

const ProjectListDiv = styled.div`
    width: 50%;
    height: 400px;
`;