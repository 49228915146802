import React from 'react'
import styled from 'styled-components';
import { devices } from '../styled-components/Devices';

export default function SchoolProject({project, total, setFocusProject, setChosenProject}) {
    return (
        <SchoolProjectDiv total={total} 
            onMouseEnter={() => setFocusProject(project)} 
            onClick={() => setChosenProject(project)}>
            <BigText>
                {project.name}
            </BigText>
            <SmallText>
                {project.shortDescription}
                <ReadMoreText>
                 READ MORE 
            </ReadMoreText>
            </SmallText>
            

        </SchoolProjectDiv>
    )
}

const SchoolProjectDiv = styled.div`
    width: 100%;
    height: ${props => (100 / props.total).toString() + "%"};
    border-bottom: 2px solid #222;
    padding: 45px;
    box-sizing: border-box;
    display: flex ;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #121212;
    color: #121212;

    
    transition: transform 0.5s;
    &:hover {
        transform: scale(1.03);
        cursor: pointer;
        background-color: #222;
    }
    



    

`;



const BigText = styled.div`
    width: 100%;
    color: #FFF;
    font-size: 1.5rem
`;

const SmallText = styled.div`
    width: 100%;
    color: #FFF;
    font-size: 0.9rem
`;

const ReadMoreText = styled.span`
    color: #45B69C;
    font-size: 0.8rem;
    float: right;
    margin-top: 0.5rem;
`;