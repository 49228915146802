import React from 'react'
//import "../css/Header.scss"
import defaultBackground from "../../media/header_bg-1.png" //../media/golden_hour.jpg" //"../media/bg4.png"
import mobileBackground from "../../media/mobileheaderBg.png" 

import { FaUniversity } from   'react-icons/fa';
import {  GiIceSkate,} from   'react-icons/gi';
import { AiOutlineGlobal } from   'react-icons/ai';
import { SiProbot } from   'react-icons/si';
import styled from 'styled-components';
import Skill from './Skill';
import ScrollDown from '../blocks/ScrollDown';
import { EndOfViewDiv } from '../styled-components/Layout';
import {gsap} from "gsap"
import { devices, isMobileQuery } from '../styled-components/Devices';
import { useMediaQuery } from 'react-responsive';

export default function Header() {

    const isMobile = useMediaQuery(isMobileQuery)
  
    window.addEventListener("mousemove", function(e){
      if (e.pageY < window.innerHeight && e.pageX < window.innerWidth && !isMobile) {
        const centerX = window.innerWidth / 2
        const centerY = window.innerHeight / 3
        gsap.to("#skills", {
          duration: 3,
          x: (centerX - (centerX - e.pageX) / 3) - 225, //+ (centerX - e.pageX) / 10,
          y: (centerY - (centerY - e.pageY) / 3),
        });
      }
    });

    return (
        
        <HeaderDiv id="header" >
          
          <FloatingDiv id="skills">
            <Skill skillName={"M.Sc. in Mechatronics"} icon={<FaUniversity/>} linkTo={"academic"}/>
            <Skill skillName={"Ice Show Skater"} icon={<GiIceSkate/>} linkTo={"skating"}/>
            <Skill skillName={"Web Developer"} icon={<AiOutlineGlobal/>} linkTo={"projects"}/>
            <Skill skillName={"Robot Developer"} icon={<SiProbot/>} linkTo={"projects"}/>
          </FloatingDiv>
          <EndOfViewDiv>
            <ScrollDown arrowColor={"black"} text={"Hi! I'm Daniel"} scrollTo={"welcome"}/>
          </EndOfViewDiv>

          
        </HeaderDiv>   
    )
}

const HeaderDiv = styled.div`
  @media ${devices.mobile} {
    background-image: url(${mobileBackground});
    background-position: left;  
  }

  background-image: url(${defaultBackground});
  background-position: right;  
  background-color: #FFF;
  background-attachment: fixed;  
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
`;

export const FloatingDiv = styled.div`
    

    @media ${devices.mobile} {
      position: relative;
      width: 100%;
      height: 100vh; 
      display: flex ;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    
    @media ${devices.laptop} {
        position: absolute;
    }
    
`;


