import React from 'react'
import styled from 'styled-components'
import { SectionContentDiv, SectionDiv, SectionHeaderDiv } from '../styled-components/Layout'
import { SectionHeader } from '../styled-components/Typography'
import TimeLine from './TimeLine'
import { experiences } from '../../data/ExperienceData'
import AnimatedSection from '../styled-components/AnimatedSection'



export default function Experience() {
    
    return (
        <ExperienceSectionDiv color='#121212'  >
            <SectionContentDiv>
                <SectionHeader color={"#FFF"} className="HeaderText">
                        Experience
                    </SectionHeader>
                    
                <TimeLine data={experiences}/>
            </SectionContentDiv>
            
        </ExperienceSectionDiv>
    )
}

const ExperienceSectionDiv = styled(SectionDiv)`
    padding-top: 150px;
    
`;