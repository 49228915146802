import React from 'react'
import Video from '../blocks/Video'
import { SectionContentDiv, SectionDiv } from '../styled-components/Layout'
import Gallery from './Gallery'
import {experiences, IntroText} from "../../data/SkatingData.js"
import background from "../../media/skating_bg-2.png" 
import { SectionHeader, SectionSubHeader, SectionText } from '../styled-components/Typography'
import styled from 'styled-components'
import { devices } from '../styled-components/Devices'
import AnimatedSection from '../styled-components/AnimatedSection'
export default function Skating() {
    return (
        <SkatingDiv id="skating" >
            <SectionContentDiv >     
            <AnimatedSection>           
                <SectionHeader color={"#121212"} className="HeaderText" >
                    Skating
                </SectionHeader>
                <SectionText color={"#121212"} >
                    {IntroText}
                </SectionText>
                </AnimatedSection>
                <AnimatedSection>  
                <ExperienceSection>
                    <SectionSubHeader marginTop={"5rem"}>
                        Show Experience
                    </SectionSubHeader>                
                    <Gallery items={experiences} />
                </ExperienceSection>
                </AnimatedSection>
                <AnimatedSection>
                <VideoSecion>
                    <SectionSubHeader>
                        Video Reel
                    </SectionSubHeader>     
                    <VideoDiv>
                        <Video url= {"https://youtu.be/mpKVTtEIk-U"}/>
                    </VideoDiv>
                </VideoSecion>
                </AnimatedSection>
                
            </SectionContentDiv>

        </SkatingDiv>
    )
}

const SkatingDiv = styled(SectionDiv)`
    padding: 150px 0px;
    background-image: url(${background});
    background-position: right;  
    background-color: #FFF;
    background-attachment: fixed;  
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    width: 100%;
    display: flex;
    @media ${devices.mobile} {
        background-image: none;  
    };
`;


const VideoDiv = styled.div`
    
    margin-top: 75px;
    height: calc(0.5625 * (60vw));
    @media ${devices.mobile} {
        margin-top: 30px;
        height: calc(0.5625 * (100vw - 40px));
    }
`

const ExperienceSection = styled.div`
    margin-top: 60px;
    @media ${devices.mobile} {
        margin-top: 100px;
    }
`
const VideoSecion = styled.div`
    @media ${devices.mobile} {
        margin-top: 100px;
        
    }
`