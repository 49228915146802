import React from 'react'
import {gsap} from 'gsap'
import styled from 'styled-components';
import { devices, isMobileQuery } from '../styled-components/Devices';
import { useMediaQuery } from 'react-responsive';

export default function Skill({ skillName, linkTo, icon }) {
    const isMobile = useMediaQuery(isMobileQuery)

    return (
        <SkillDiv onClick={() => {
            var Scroll = require('react-scroll');
            var scroller = Scroll.scroller;
            scroller.scrollTo(linkTo, {
                duration: 1000,
                smooth: 'easeInOutQuint',
            });
        }}>
            {!isMobile && icon}
            <Text>
                {skillName}
            </Text>
        </SkillDiv>
    )
}

const SkillDiv = styled.div`
    @media ${devices.mobile} {
        font-size: 1.5rem;
        padding-left: 10px;
        font-weight: lighter;
        
        display:flex ;
        flex-direction: column;
    }
    
    font-size: 2.5rem;
    
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
        font-weight: bold;
    }
`;

const Text = styled.div`
    font-family: "Exo 2", "sans-serif";
    margin-left: 1rem;
    @media ${devices.mobile} {
        margin: 5px 0px 20px 0px;

    }
`;