import styled from "styled-components";
import { devices } from "./Devices";




export const SectionDiv = styled.div`
    min-height: 100vh;
    
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.color ? props.color : "#555"};
    padding-top: ${props => props.paddingTop ? props.paddingTop : "0px"};
    color: #FFF
`;

export const SectionContentDiv = styled.div`
    width: 60%;
    margin-left: 20%;
    box-sizing: border-box;
    @media ${devices.mobile} {
        margin-left: 20px;
        width: calc(100% - 40px);
    }
`;


export const SectionHeaderDiv = styled.div`
    width: 100%;
    padding-left: 20%;
    box-sizing: border-box;
    
`;




export const EndOfViewDiv = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
`;