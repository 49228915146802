import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { isMobileQuery } from './Devices';
import { motion, Variants } from "framer-motion";



export default function AnimatedSection({children}) {
    const isMobile = useMediaQuery(isMobileQuery)

    const cardVariants = {
    offscreen: {
        opacity: 0,
        y: 300
    },
    onscreen: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.8,
            ease: "easeInOut",
        }
    },
    onexit: {
        opacity: 0,
        y: -300
    }
    };


    /*if (!isMobile) {
        return <div>{children}</div>
    } */

    return (
        <motion.div
            initial="offscreen"
            whileInView="onscreen"
            exit={"onexit"}
            viewport={{ once: true, amount: 0.4 }}
            >
            <motion.div variants={cardVariants}>
                {children}
            </motion.div>
        </motion.div>
    )
}
