import React, { createRef, useRef, useState } from 'react'
import ReactPlayer from "react-player"
import { useMediaQuery } from 'react-responsive'
import { isMobileQuery } from '../styled-components/Devices'
import screenfull from "screenfull";  
import { findDOMNode } from 'react-dom'

export default function Video(props) {
    const isMobile = useMediaQuery(isMobileQuery)

    return (
                <ReactPlayer
                    url={props.url}
                    muted={true}
                    playing={!isMobile}
                    controls={true}
                    loop={!isMobile}
                    
                    width="100%"
                    height="100%"

                />     
    )
}
