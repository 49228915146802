import gsap from 'gsap';
import React from 'react'
import styled from 'styled-components';
import { CallsToAction } from '../styled-components/Typography';
import ArrowsDown from "./ArrowsDown"
import {  MdOutlineKeyboardArrowDown } from   'react-icons/md';
import { useMediaQuery } from 'react-responsive';
import { isMobileQuery } from '../styled-components/Devices';
export default function ScrollDown({arrowColor, text, scrollTo}) {
    const isMobile = useMediaQuery(isMobileQuery)
    const scrollDown = () => {
        var Scroll = require('react-scroll');
        var scroller = Scroll.scroller;
        scroller.scrollTo(scrollTo, {
            duration: 1000,
            smooth: 'easeInOutQuint',
        });
    }
    return (
        <ScrollDownDiv onClick={() => isMobile && scrollDown()}>
            {text && <CallsToAction color={"#121212"}  >{text}</CallsToAction>}
            {isMobile 
                ? <MdOutlineKeyboardArrowDown size={"50px"}  /> 
                : <ArrowsDown onclick={scrollDown} color={arrowColor}/>
            }
        </ScrollDownDiv>   
    )
}

const ScrollDownDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const ScrollDownText = styled(CallsToAction)`
    font-size: 2rem;
`;