import LEAD from "../media/lead-logo.png"
import WoodEye from "../media/woodeye-logo.png"
import SAAB from "../media/saab-logo.png"
import Dyno from "../media/dynologo.png"
import LIU from "../media/liulogo.png"
import FIA from "../media/fialogo.png"
import MyBusiness from "../media/mybusinesslogo.png"
import LiUAIF from "../media/liuaiflogo.png"







export const experiences = [
    {
        header: "Robot Developer at Dyno Robotics",
        text: "Developing a social robot for the well known air force museum in Linköping (Flygvapenmuseum). The robot is developed to participate in science shows for kids, help in the experiment room and answer questions about the museum exhibition.",
        image: Dyno,
        timespan: "Sep 2022 - Now"
    },
    {
        header: "Graduated at Linköping University",
        text: "Master of Science in Applied Physics and Electrical Engineering with Specialization in Mechatronics",
        image: LIU,
        timespan: "Dec 2021"
    },
    {
        header: "Member of Robot Developer Team FIA at Linköping University",
        text: "Programmed a Pepper, a humanoid service robot, for RoboCup - World" + 
        " Championships in Robotics. Unfortunately this event never happened due to COVID-19, but I gained experience" + 
        " of working with Natural Language Processing, ROS, State Machines and robots in general.",            
        image: FIA, 
        timespan: "Feb 2020 - Aug 2021"
    },
    {
        header: "Research & Development Project - Sommarmatchen at LEAD (Business Incubator) via UniTalent",
        text: "Helped researchers in a MedTech startup to commercialize and develop their product. The work included technical and market research, programming and making a webiste.",
        image: LEAD,
        timespan: "Jun 2020 - Aug 2020"
    },
    {
        header: "Started my own business",
        text: "Started doing paid projects related to web, app and robot development." + 
        " Many of these projects are presented in the Projects-section above. ",
        image: MyBusiness,
        timespan: "Jul 2020"
    },
    {
        header: "Technical Translator in Russian Consult at WoodEye AB via Xamera",
        text: "The company (now named Microtec) makes advanced board scanners for wood industries. I translated technical documents from English/Swedish to Russian and worked as an interpreter for Russian customers during courses and installations.",
        image: WoodEye,
        timespan: "Jun 2019 - May 2020"
    },
    {
        header: "VR Developer at SAAB Aeronautics",
        text: "Developed a VR Flight Simulator in Unity.",
        image: SAAB,
        timespan: "Jun 2019 - Aug 2019"
    },
    {
        header: "Head of IT & Communication at LIU AIF",
        text: "Responsible for the website, social media, and newsletters at" +
            " a student sport association at Linköping University. During this " +
            " period I was also a part of the project group that arranged Studentiaden - one of " + 
            " the biggest sport-events for students in Sweden",
        image: LiUAIF, 
        timespan: "Aug 2018 - Jun 2019"
    },
    {
        header: "Summer Internship at SAAB Dynamics",
        text:   "During this summer I did a few smaller tasks such as learning " + 
                " and writing manuals to a new program, writing VBA scripts and designing reports.",
        image: SAAB,
        timespan: "Jun 2018 - Aug 2018"
    },
]