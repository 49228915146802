import React, { useState } from 'react'
import styled from 'styled-components'
import { devices } from '../styled-components/Devices'

export default function GalleryItem({item}) {
  const [showText, setShowText] = useState(false)
  return (
    <GalleryItemDiv onMouseEnter={() => setShowText(true)} onMouseLeave={() => setShowText(false)}>
      <Image src={item.image} blur={showText}/>
      { showText && <TextDiv>
        <Title>
          {item.title}
        </Title>
        <Years>
          {item.years}
        </Years>
        <Location>
          {item.location}
        </Location>
      </TextDiv>}
    </GalleryItemDiv>
  )
}


const GalleryItemDiv = styled.div`
  height: 225px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media ${devices.mobile} {
    height: 100%;
    width: 50%;
  }
`

const Image = styled.img`
    height: 90px;
    position: relative;
    object-fit: cover;
    margin: 15px;
    opacity: ${props => props.blur ? 0.2 : 1};
    @media ${devices.mobile} {
      object-fit: contain;
      width: 100%;
    }
`

const TextDiv = styled.div`
    color: #121212;
    position: absolute;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 90px;
    padding: 30px;
    font-weight: bold;
    @keyframes fadeIn {
        0% {opacity: 0%;}
        100% {opacity: 100%;}
    }
    animation-name: fadeIn;
    animation-duration: 0.5s;
`

const Title = styled.div`
  
`

const Years = styled.div`
`

const Location = styled.div`
`