import React, { useState } from 'react'
import styled from 'styled-components';
import { devices } from '../styled-components/Devices';
import TimeLineItem from './TimeLineItem';

export default function TimeLine({data}) {
    const [focus, setFocus] = useState(false)

    return (
        <div>
        {data.length > 0 && (
            <TimeLineContainer className="timeline-container">
                {data.map((data, idx) => (
                    <TimeLineItem 
                        data={data} 
                        key={idx} 
                        id={idx} 
                        setFocus={setFocus} 
                        focus={focus}/>
                ))}
            </TimeLineContainer>
        )}
        </div>
    )
}

const TimeLineContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 200px;
    margin-bottom: 150px;
    @media ${devices.mobile} {
        margin-top: 50px;
    }
    ::after {
        background-color: #45B69C;
        content: '';
        position: absolute;
        left: calc(50% - 2px);
        width: 4px;
        height: 100%;

        @media ${devices.mobile} {
            left: 10px
        }
    }
`
