
import styled from "styled-components";
import { devices } from "./Devices";

export const SectionHeader = styled.div`
    font-family: 'Exo 2', sans-serif;
    font-weight: 200;
    font-size: 5rem;
    @media ${devices.mobile} {
        font-size: 4rem;
        text-align: start;
    }
    color: ${props => props.color ? props.color : "#000"};
`;

export const SectionSubHeader = styled.div`
    font-family: 'Exo 2', sans-serif;
    font-weight: 600;
    font-size: 2.5rem;
    color: ${props => props.color ? props.color : "#000"};
    @media ${devices.mobile} {
        font-size: 1.8rem;
    }
`;

export const SectionText = styled.div`
    
    margin-top: 45px;   
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    color: ${props => props.color ? props.color : "#FFF"};
    @media ${devices.mobile} {
        font-size: 1rem;
        line-height: 1.7rem;
        margin-top: 50px;
        margin-bottom: 50px;
        font-weight: 300;
    }
`;

export const CallsToAction = styled.div`
    font-family: 'Reenie Beanie', cursive;
    font-style: italic;
    font-weight: 400;
    font-size: ${props => props.size ? props.size : "5rem"};
    @media ${devices.mobile} {
        font-size: ${props => props.size ? props.size : "3rem"};
    }
    
    color: ${props => props.color ? props.color : "#45B69C"};
`;

