import React from 'react'
import styled from 'styled-components'
import { SectionContentDiv, SectionDiv, SectionHeaderDiv } from '../styled-components/Layout'
import { SectionHeader, SectionText } from '../styled-components/Typography'
import ProjectsCarousel from './ProjectsCarousel'
import {IntroText, projects} from "../../data/ProjectsData"
import AnimatedSection from '../styled-components/AnimatedSection'
import { devices } from '../styled-components/Devices'

export default function Projects() {

    return (
        <ProjectSectionDiv id="projects" color="#121212" >
            <AnimatedSection>
                <SectionContentDiv>
                    <SectionHeader color={"#FFF"} className="HeaderText">
                        Projects
                    </SectionHeader>
                    <SectionText >
                        {IntroText}

                    </SectionText>
                </SectionContentDiv>
            </AnimatedSection>
            <AnimatedSection>
                <MobileSectionContentDiv>
                    <ProjectsCarousel content={projects} scrollTo={"projects"} textColor = {"#FFF"}/>
                </MobileSectionContentDiv>
            </AnimatedSection>
        </ProjectSectionDiv>
    )
}

const ProjectSectionDiv = styled(SectionDiv)`
    padding-top: 150px;
`;

const MobileSectionContentDiv = styled.div`
    box-sizing: border-box;
    @media ${devices.mobile} {
        margin-left: 20px;
        width: calc(100% - 40px);
    }
`;

