import React from 'react'
import styled from 'styled-components'

export default function ArrowsDown(props) {
    return (
        <ArrowsContainer onClick={props.onclick}>
            <polygon style={{fill: props.color}} className="arrow-middle" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "/>
            <polygon style={{fill: props.color}} className="arrow-bottom" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>
        </ArrowsContainer>
    )
}

const ArrowsContainer = styled.svg`
    width: 75px;
    height: 72px;
    cursor: pointer;
        &:hover {
            
            polygon {
            fill: #000;
            transition: all .2s ease-out;
        
            &.arrow-bottom {
                transform: translateY(-18px);
            }
        
            &.arrow-top {
                transform: translateY(18px);
            }
            
            }
        
        }
`;

