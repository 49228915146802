import React, { useState } from "react";
import styled from "styled-components";
import { devices } from "../styled-components/Devices";

const FORM_ENDPOINT = "https://public.herotofu.com/v1/57033b00-52d3-11ed-9f58-f3ab7f1a635a"; // TODO - fill on the later step

export  default function ContactForm() {
    const [submitted, setSubmitted] = useState(false);
    const handleSubmit = () => {
        setTimeout(() => {
        setSubmitted(true);
        }, 100);
    };

    if (submitted) {
        return (
        <>
            <h2>Thank you!</h2>
            <div>We'll be in touch soon.</div>
        </>
        );
    }

    return (
        <Form
            action={FORM_ENDPOINT}
            onSubmit={handleSubmit}
            method="POST"
            target="_blank"
            autoComplete="false"
        >
            <ShortTextInput type="text" placeholder="Your name" name="name" required />
            <ShortTextInput type="email" placeholder="Email" name="email" required />

            <LongTextInput placeholder="Your message" name="message" required />
            <Button type="submit"> Send a message </Button>
        </Form>
    );
    };

const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    
    
`;

const ShortTextInput = styled.input`
    width: 25rem;
    text-align:center ;
    margin-bottom: 1rem;
    color: #FFF;
    background-color: #121212;
    border: none;
    border-bottom: solid 2px #FFF;
    font-size: 1.5rem;

    &:focus-visible{
        outline: none

    }

    @media ${devices.mobile} {
        width: 70%;
    }
`;

const LongTextInput = styled.textarea`
    width: 35rem;
    min-height: 8rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
    font-size: 1.1rem;
    color: #121212;
    background-color: #FFF;
    padding: 1rem;
    border: none;
    font-family: "Exo 2", sans-serif;

    @media ${devices.mobile} {
        width: 70%;
    }
`;

const ButtonDiv = styled.div`
    border: none;
`;

const Button = styled.button`
    font-size: 1.2rem;
    border: solid 2px #45B69C;
    padding: 1rem;
    border-radius: 10px;
    color: #45B69C;
    background-color: #121212;
    cursor: pointer;
    &:hover {
        background-color: #45B69C;
        color: #121212;
    }
`;