import React, {useState, useRef, useEffect} from 'react'
import {AiOutlineArrowRight, AiOutlineArrowLeft, AiOutlineUp} from 'react-icons/ai'
import {gsap} from 'gsap'
import ArrowsDown from "../blocks/ArrowsDown"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styled from 'styled-components'
import ProjectSimpleView from './ProjectSimpleView'
import ProjectDetailedView from './ProjectDetailedView';
import {getOriginalCounterPart} from 'react-multi-carousel/lib/utils';
import { useMediaQuery } from 'react-responsive';
import { devices, isMobileQuery } from '../styled-components/Devices';
import { useNavigate } from "react-router-dom";
import Popup from './Popup';


export default function ProjectsCarousel({content, textColor}) {
    const isMobile = useMediaQuery(isMobileQuery)
    let navigation = useNavigate();


    const [focusSlide, setFocusSlide] = useState(false)
    const [chosenProject, setChosenProject] = useState(false)

    const currentSlideToIndex = (prop) => {
        const bigIndex = getOriginalCounterPart(prop.currentSlide, prop, React.Children.toArray(projects));
        const maxBigIndex = prop.totalItems
        const minBigIndex = maxBigIndex - projects.length
        //console.log("bigIndex", bigIndex, "maxBigIndex", maxBigIndex, "minBigIndex", minBigIndex, "prop", prop) 

        if (bigIndex >= minBigIndex) {
            return bigIndex - minBigIndex
        } else if (prop.slidesToShow == 3) {
            return bigIndex - 4
        } else if (prop.slidesToShow == 2) {
            return bigIndex
        } else if (prop.slidesToShow == 1) {
            return bigIndex + 4
        }
    }


    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
            },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
            },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
            }
        };

    const projects = content.map((project, index) => 
        <ProjectSimpleView  focusSlide={focusSlide} 
                            key={index} 
                            project={project} 
                            setChosenProject={isMobile 
                                ? _ => navigation("/project", { state: {project : project}})
                                : setChosenProject}
                            thisSlide={index}
                            totalSlides = {content.length}
                            />) 


    return (
        <CarouselDiv className="Projects" color={textColor}>
            <Carousel 
                swipeable={true}
                draggable={true}
                infinite={true}
                autoPlay={!chosenProject}
                autoPlaySpeed={3000}
                transitionDuration={500}
                centerMode={!isMobile}
                focusOnSelect={true}
                showDots={true}
                arrows={!isMobile && !chosenProject}
                responsive={responsive}
                afterChange={(nextSlide, prop) => {
                    
                    const focusIndex = currentSlideToIndex(prop)
                    //console.log("currentSlide", prop.currentSlide, "slidesToShow", prop.slidesToShow, "nextSlide", nextSlide, "focusIndex", focusIndex ); 
                    setFocusSlide(focusIndex)}}>
                {projects}
            </Carousel> 
            

            {chosenProject && <Popup close={() => setChosenProject(false)}> 
                <ProjectDetailedView 
                    project={chosenProject}
                    close={() => setChosenProject(false)} />
            </Popup>}               
            
        </CarouselDiv>
    )
}

const CarouselDiv = styled.div`
    width: 100%;
    color: ${props => props.color};
    /*margin-top: -50px;*/
    @media ${devices.mobile} {
        padding-bottom: 200px;
        

    }
`; //background-color: #121212;    