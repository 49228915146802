import PepperImage from "../media/Pepper.png"
import RobotDogImage from "../media/robotdog.jpg"
import SportyDiaryImage from "../media/sportydiary.png"
import AutoSpeakerImage from "../media/AutoSpeakerImage.jpg"
import LandyImage from "../media/landy.png"
import PepperLive from "../media/pepper_live.png"
import LKFweb from "../media/lkfweb.png"
import SAIF from "../media/saif.png"

export const IntroText = 
"Robotics and AI. Websites and Apps. It's all about automation " +
"and digitalization to make daily life " +
"easier and more enjoyable. I always focus on creating personalized " +
" and customer friendly solutions with good communication."

export const projects = [
    {
        name: "Landy",
        shortDescription: "Chat Robot and Booking System for Landeryd's Golf Club",
        longDescription: "The biggest project so far. A chat robot that reduces the amount of emails " + 
        " that needs to be answered by the office personal. The robots automaticly answers to more than " + 
        " 100 questions 24/7 and helps customers to book phone meetings. This is how Landy system works:" + 
        "\n1. Customer emails a question to the office\n2. Robots sends an email back with a link to the " + 
        " robot's website\n3. Customer chats with robot on the robot's website\n 4. If question does not get " + 
        " answered: Robot helps customer to book a phone meeting\n 5. If question gets answered or a " + 
        " meeting was booked: Robot let the office know that the question in the email does not need to " + 
        " be answered\nLanderyds GK is an offical reference customer for this project. For reference, " + 
        " contact their CEO, Björn, at bjorn.sturehed@landerydsgolf.se. ",
        image: LandyImage
    },
    {
        name: "Taking Pepper to Events",
        shortDescription: "Interviews, high fives and guitar solos at live events",
        longDescription: "How do you make a digital live event more interesting?\nAdd a cute robot that can make jokes, dance and do interviews.\Fpr a couple live envents with hundreds of people in the audience I programmed and operated the humanoid Pepper robot.",
        image: PepperLive,
        youtubeURL: "https://youtu.be/5btOoui6OQY"
    },
    {
        name: "Pepper for RoboCup",
        shortDescription: "Member of Worlds Championships in Robotics Team",
        longDescription: "Toghether with my team LiU@HomeWreckers at Linköping University I was developing a Pepper robot for World Championships in Robotics 2021 in France that unfortunately never happened. Pepper is a humanoid robot, whos purpose is to be able to do work in a reception, as a guide, welcoming guests etc. \n \n I have worked with the robot's NLP (Natural language Processing), tablet, state machine and drivers for moving and hearing.",
        image: PepperImage
    },
    {
        name: "Auto Speaker",
        shortDescription: "An autonomous speaker for future figure skating competitions",
        longDescription: "Today's secretariat at figure skating competitions needs: \n \n - 2 Persons: one speaker and one responsible for music \n - Multiple computers, a lot of paper and sound system for both computer and microphones \n - Stress, high concentration and long days leading to human errors \n \n Auto speaker is the replacement for all this. All you need to do is press Play! \n \nAuto Speaker... \n - Fetches results live at the competition \n - Reads the competition database and creates a speaker script\n - Plays program music\n - Plays pause music via Spotify \n - Has a timer for warmup",
        image: AutoSpeakerImage,
        youtubeURL: "https://www.youtube.com/watch?v=W4YTXUqLY9c"
    },
    /*{
        name: "Robot Dog",
        shortDescription: "A color following robot",
        longDescription: "My own arduino project. Using a Pixy2 camera, an Arduino, step motors and some electronic components I built a robot that follows a given color.",
        image: RobotDogImage,
    },*/
    {
        name: "Sporty Diary",
        shortDescription: "App for Figure Skaters",
        longDescription: "Sporty Diary is " + 
        "a training diary made for serious figure skaters to journal " + 
        "their progress and habits to get statistics for optimizing their training. " + 
        " Using my self made system and these statistics the athlete will be able to find " + 
        " his/hers optimal sleep, food, week day etc.",
        image: SportyDiaryImage
    },
    {
        name: "Website for SAIF",
        shortDescription: "SAIF: Swedish University Sports Federation",
        longDescription: "Designed and created parts of the website for the Swedish University Sports Federation. Take a look!",
        image: SAIF,
        links: [
            {
                label: "Go to Website",
                link: "https://studentidrott.se/"
            }
        ]
    },
    {
        name: "Website for LKF",
        shortDescription: "LKF: Linköping Figure Skating Association",
        longDescription: "Designed and created a website for my home Figure Skating Club in Linköping. Take a look!",
        image: LKFweb,
        links: [
            {
                label: "Go to Website",
                link: "https://linkopingskf.se/wordpress/"
            }
        ]
    },
]