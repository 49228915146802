import React from 'react'
import styled from 'styled-components'
import { devices } from '../styled-components/Devices'
import GalleryItem from './GalleryItem'

export default function Gallery({items}) {
    return (
        <GalleryDiv>
            {items.map( (item, index) => 
                <GalleryItem item={item} key={index}/> 
            )}
        </GalleryDiv>
    )
}


const GalleryDiv = styled.div`
    display: flex;
    justify-content: space-between;
    @media ${devices.mobile} {
        flex-wrap: wrap;
        justify-content: center;
        padding: 20px
    }
`