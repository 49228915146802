import React from 'react'
import {gsap} from 'gsap'
import ArrowsDown from "../blocks/ArrowsDown"
import styled from 'styled-components';
import { SectionDiv, EndOfViewDiv, SectionContentDiv } from '../styled-components/Layout';
import ContactInfo from '../blocks/ContactInfo';
import { FaPhoneAlt, FaLinkedin } from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';
import ContactForm from './ContactForm';
import { CallsToAction } from '../styled-components/Typography';
import { devices } from '../styled-components/Devices';

export default function Contact() {
    return (
        <SectionDiv id="welcome" color="#121212" >
        <CenterDiv>
              <SmallTextIntroDiv>
                <SmallText>
                    Project ideas, contract offers, collaborations or just talk? 
                </SmallText>
                <CallsToAction>
                Let's keep in touch!
                </CallsToAction>
              </SmallTextIntroDiv>
              <ContactForm />
              <ContactInfoDiv>              
                <ContactInfo content={"daniel@engelsons.org"} icon = {<GrMail/>} link= "mailto: daniel@engelsons.org" />
                <ContactInfo content={"+46 709 54 63 97"} icon = {<FaPhoneAlt/>}/>
                <ContactInfo content="Chat on LinkedIn" icon = {<FaLinkedin/>} link="https://www.linkedin.com/in/daniel-engelson-a5114b154/"  />
              </ContactInfoDiv>
          
          </CenterDiv>
        </SectionDiv>   
    )
}
const CenterDiv = styled.div`
    height: 100%;
    width: 100%;
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    
`;

const ContactDiv = styled.div`

`;

const ContactInfoDiv = styled.div`
    margin-top: 3rem;
    display: flex;
    width: 100%;
    justify-content: space-around;

    @media ${devices.mobile} {
        flex-direction: column;
        margin-left: 50%;
    }
`;

const BigTextIntroDiv = styled.div`
    font-size: 35px;
    color: #E3E3E3;
    width: 40%;
`;

const SmallTextIntroDiv = styled.div`
    font-size: 50px;
    color: #E3E3E3;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;
`;
const SmallText = styled.div`
    font-size: 19px;
    line-height: 23px;
    font-weight: 300;
    color: #E3E3E3;
    margin-bottom: 30px;
    width: 30%;
    text-align: center;
    @media ${devices.mobile} {
        width: 60%;
    }
`