import React, { useEffect } from 'react'
import styled from 'styled-components';
import {ImCross} from 'react-icons/im'
import { devices } from '../styled-components/Devices';
import { useNavigate, useLocation } from "react-router-dom";
import ProjectDetailedView from './ProjectDetailedView';
/*...*/

export default function MobilePopup({scrollTo}) {
    let location = useLocation();
    const navigation = useNavigate()
    const project = location.state.project 
    
    return (
        <MobilePopupDiv>
            <Exit onClick={() => {
                navigation("/", { state: {scrollTo : scrollTo}})}}>
                <ImCross/>
            </Exit>
            <ProjectDetailedView project = {project} />
        </MobilePopupDiv>
    )
}

const MobilePopupDiv = styled.div`
    background-color: #1F1F1F;
    min-height: 100vh;
`;

const Exit = styled.div`
    position: fixed;
    left: calc(100vw - 70px);
    top: 12px;
    color: #FFF;
    z-index: 900;
    border: solid 5px #333;
    border-radius: 100%;
    padding: 1rem;
    width: 1rem;
    height: 1rem;
    display: flex;
    justify-content: center;
    font-weight: bolder;
    background: #333;
    cursor: pointer;
`;