import React, { useState, useEffect, useRef } from 'react'
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { devices, isMobileQuery } from '../styled-components/Devices';
import TimeLineItemContent from './TimeLineItemContent';

export default function TimeLineItem({ data, id, setFocus, focus }) {
    const even = id % 2 == 0
    


    const [lowerDivAnimation, setlowerDivAnimation] = useState(false)
    const isMobile = useMediaQuery(isMobileQuery)
    const [height, setHeight] = useState(0)
    const [lowerDivHeight, setLowerDivHeight] = useState(0)
    const ref = useRef(null)
    const lowerDivRef = useRef(null)

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    }, [ref])


    return (
        <TimeLineItemDiv    className="timeline-item" 
                            even={even} onMouseEnter={() => { 
                                setlowerDivAnimation("In")}} 
                            onMouseLeave={() => { 
                                setlowerDivAnimation("Out")}}
                            
                            >
            <TimeLineItemContentDiv className="timeline-item-content" even={even}
                onClick={() => {
                    if (!isMobile) {
                        return
                    }
                    if (lowerDivAnimation == "In") {
                        setlowerDivAnimation("Out")
                    } else {
                        setlowerDivAnimation("In")
                    }
                    }
                }
                ref={ref}
                height={height}
                >
                <TimeLineItemContent data={data} />
                
                <Circle className="circle" even={even} height={height} />
                
                
            </TimeLineItemContentDiv>
            {true && <LowerDiv   even={even} 
                        animation={lowerDivAnimation}
                        ref={lowerDivRef}
                        height={lowerDivHeight}>         
                <Description>
                    {data.text}
                </Description>
            </LowerDiv>}
        </TimeLineItemDiv>
    );
}


const TimeLineItemDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.even ? "flex-end" : "flex-start"};
    padding-right: ${props => props.even ? "30px" : "0px"};
    padding-left: ${props => props.even ? "0px" : "30px"};
    position: relative;
    
    margin-top: -75px;
    margin-bottom: 75px;
    width: 50%;
    align-self: ${props => !props.even && "flex-end"};;

    @media ${devices.mobile} {
        justify-content: flex-start;
        padding: 0px 10px;
        width: 100%;
        align-self: flex-start;
        margin: 5px 0px;

    }

    
`;

const TimeLineItemContentDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${props => props.even ? "flex-end" : "flex-start"};
    border: none blue 1px;
    border-radius: 2px;
    padding: 20px;
    cursor: pointer;
    box-sizing: border-box;
    margin-right: ${props => props.even ? "50px" : "0px"};
    margin-left: ${props => props.even ? "0px" : "50px"};
    background-color: #1F1F1F ;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    z-index: 2;

    @media ${devices.mobile} {
        justify-content: flex-start;
        margin-left: 10px;
        margin-right: 0px
    }

    &:hover{
        background-color: #2F2F2F ;
        /*border-left: 1px #FFF solid;
        border-right: 1px #FFF solid;
        border-top: 1px #FFF solid;
        @media ${devices.mobile} {
            border: none
        }*/
    }

    ::after {
        background-color: #45B69C;
        content: '';
        position: absolute;
        right: ${props => props.even ? "" : "calc(100% - 80px)"};
        left: ${props => props.even ? "calc(100% - 80px)" : ""};
        top: ${props => (0.5*props.height).toString() + "px"};
        width: 50px;
        height: 4px;

        @media ${devices.mobile} {
            display: none;
        }
    }
`;

const Circle = styled.span`
    background-color: #121212;
    border: 5px solid #45B69C;
    border-radius: 50%;
    position: absolute;
    top: ${props => (0.5*props.height - 10).toString() + "px"};
    right: ${props => props.even ? "15px" : ""};
    left: ${props => props.even ? "" : "15px"};
    width: 20px;
    height: 20px;
    z-index: 100;

    @media ${devices.mobile} {
            right: 0px;
            left: -3px
    }
`



const LowerDiv = styled.div`
    @keyframes goDown {
        0% {top: 0%; opacity: 0;}
        100% {top: 100%; opacity: 1;}
    }
    @keyframes goUp {
        0% {top: 100%; opacity: 1;}
        100% {top: 0%; opacity: 0;}
    }
    @keyframes goDownRelative {
        0% {max-height: 0px; opacity: 0; padding-top: 0px; padding-bottom: 0px;}
        100% {max-height: 300px;  opacity: 1; padding-top: 20px; padding-bottom: 20px;}
    }
    @keyframes goUpRelative {
        0% {max-height: 300px; opacity: 1; padding-top: 20px; padding-bottom: 20px;}
        100% {max-height: 0px;  opacity: 0; padding-top: 0px; padding-bottom: 0px;}
    }
    


    position: relative;
    top: 100%;
    width: ${props => props.even ? "calc(100% - 50px)" : ""};
    justify-content: ${props => props.even ? "flex-end" : "flex-start"};
    background-color: #FFF;
    color: #121212;
    padding:  ${props => props.animation == "In"  ? "20px 20px" : "0px 20px"};
    box-sizing: border-box;
    margin-left: ${props => props.even ? "0px" : "50px"};
    animation-name: ${props => props.animation == "In" ? "goDownRelative" : "goUpRelative"};
    opacity: ${props => props.animation == "In" ? 1 : 0};
    max-height: ${props => props.animation == "In"  ? "300px" : "0px"};
    animation-duration: 0.5s;
    z-index: 1;

    @media ${devices.mobile} {
        width: calc(100% - 10px);
        justify-content: "flex-start";
        margin-left: 10px;
        position: relative;
        top: 0px;
        /*margin-top: ${props => props.animation == "In" ? "0px" : -props.height.toString() + "px"};*/
        animation-name: ${props => props.animation == "In" ? "goDownRelative" : "goUpRelative"};
    }
`;  


const Description = styled.div`

`;

