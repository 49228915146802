import React from 'react'
import styled from 'styled-components';
import Video from '../blocks/Video';
import { SectionHeader, SectionSubHeader, SectionText } from '../styled-components/Typography';
import masterThesis from "../../media/Daniel Engelsons_Master_Thesis.pdf"
import { devices, isMobileQuery } from '../styled-components/Devices';
import { useMediaQuery } from 'react-responsive';
import { ResearchText } from '../../data/AcademicData';
export default function MasterThesis() {
    const isMobile = useMediaQuery(isMobileQuery)
  return (
    <MasterThesisDiv>
        <TextDiv>
            <div>
                <MasterThesisSectionSubHeader color='#121212'>
                    Research
                </MasterThesisSectionSubHeader>
                {true && <VideoDiv>
                    <Video  url= {"https://www.youtube.com/watch?v=wpZ9rBC_bkk"} />
                </VideoDiv>}
                <Text>
                    {ResearchText}
                    
                </Text>
            </div>
            <ButtonsContainer>
                <ReadMoreButton href="https://ieeexplore.ieee.org/document/9811941" target={"_blank"}>
                    Read Paper
                </ReadMoreButton>
                <ReadMoreButton href="http://liu.diva-portal.org/smash/record.jsf?pid=diva2%3A1683117&dswid=-1611" target={"_blank"}>
                    Read Thesis
                </ReadMoreButton>
            </ButtonsContainer>
        </TextDiv>
        {false && <VideoDiv>
            <Video  url= {"https://www.youtube.com/watch?v=wpZ9rBC_bkk"} />
        </VideoDiv>}
    </MasterThesisDiv>
  )
}


const MasterThesisSectionSubHeader = styled(SectionSubHeader)`
    
    @media ${devices.mobile} {
        margin-bottom: 30px;
    }
`;

const MasterThesisDiv = styled.div`
    width: 100%;
    display: flex;
    padding-top: 30px;
    @media ${devices.mobile} {
        flex-direction: column;
        margin-top: 130px;
        padding-top: 0px;
    }
`;

const TextDiv = styled.div`
    width: 100%;
    @media ${devices.mobile} {
        width: 100%;
    }
    display:flex ;
    flex-direction: column;
`;

const Text = styled(SectionText)`
    margin-top: 30px;
    color: #121212;
    padding-right: 75px;
    @media ${devices.mobile} {
        padding-right: 0px;
    }
`;

const VideoDiv = styled.div`
    width: 100%;
    margin-left: 0%;
    height: calc(0.5625 * (1 * 59.5vw));
    margin-top: 30px;
    @media ${devices.mobile} {
        margin-top: 30px;
        width: 100%;
        height: calc(0.5625 * (100vw - 40px));
    }
`;
const ButtonsContainer = styled.div`
    display: flex ;
    width: 100%;
    margin-top: 30px;
    justify-content: center;
    @media ${devices.mobile} {
        margin-top: -20px;
        justify-content: space-around;
        margin-bottom: 30px;
    }
    /*;*/
`;



const ReadMoreButton = styled.a`
    padding: 15px 30px;
    border: 2px solid #FFF;
    text-decoration: none;
    color: #FFF;
    background-color: #121212;
    width: fit-content;
    border-radius: 5px;
    margin-right: 15px;

    @media ${devices.mobile} {
        padding: 10px 15px;
        margin-right: 0px;
        width: 100%;
        display: flex;
        justify-content: center;
    }


    &:hover{
        background-color: #FFF;
        color: #45B69C;
        border: 2px solid #45B69C;
    }
`;